import { StateSelector, DispatchSelector } from 'reduxm/selectors'
import { connect } from 'react-redux'
import { AudioRecord } from './index'

const mapStateToProps = state => ({
	uploadState: StateSelector.whisper.recognize.upload(state)
})

const mapDispatchToProps = {
	upload: DispatchSelector.whisper.recognize.upload,
	getStatus:  DispatchSelector.whisper.recognize.status,
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioRecord)
