import { RecognizeActions, RecognizeSelectors } from './modules/Whisper/recognite'
import { ForecastActions, ForecastSelectors } from './modules/Whisper/forecast'

export const StateSelector = {
	whisper: {
		recognize: RecognizeSelectors,
		forecast: ForecastSelectors,
	}
}

export const DispatchSelector = {
	whisper: {
		recognize: RecognizeActions,
		forecast: ForecastActions,
	}
}
