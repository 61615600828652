import AAudioPlayer from 'react-modern-audio-player'
import ReactAudioPlayer from 'react-audio-player'
import { API_SIMPLE_STORAGE } from 'config'
import { Alert, CircularProgress } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

let bufferSound;
let xhr = new XMLHttpRequest();

// xhr.onload = function() {
// 	console.log(`Загружено: ${xhr.status} ${xhr.response}`);
// };
//
// xhr.onprogress = function(event) { // запускается периодически
//                                    // event.loaded - количество загруженных байт
//                                    // event.lengthComputable = равно true, если сервер присылает заголовок Content-Length
//                                    // event.total - количество байт всего (только если lengthComputable равно true)
// 	console.log(`Загружено ${event.loaded} из ${event.total}`);
// };

export const AudioPlayer = ({ audio }) => {
	const [error, setError] = useState(false)
	const [src, setSrc] = useState()
	const [isFetch, setIsFetch] = useState(false)

	const playList = useMemo(() => [
		{
			src: src,// `${API_SIMPLE_STORAGE}/${audio}`,
			id: 1
		},
	], [src])

	useEffect(() => {
		if(audio){
			setIsFetch(true)
			xhr.open('GET', `${API_SIMPLE_STORAGE}/${audio}`, true);
			xhr.responseType = 'arraybuffer';
			xhr.addEventListener('load', bufferSound, false);

			xhr.onreadystatechange = (event) => {
				const { currentTarget: { readyState, response } } = event
				console.log(event)
				if(readyState===4){
					const blob = new Blob([response], { type: "audio/mp3" });
					const url = window.URL.createObjectURL(blob)
					setSrc(url)
					setIsFetch(false)
					console.log(url);
				}
			}

			xhr.send();
		}
	}, [audio])

	return (
		<div style={{ paddingBottom: '10px', width: '80vw', display: 'flex', justifyContent: 'center'}}>
			{isFetch ?
				<CircularProgress />
				: (src && !error ?
					<ReactAudioPlayer
						src={src}
						controls
						loadstart={() => "load"}
						playList={playList}
						activeUI={{
							playButton: true,
							trackTime: true,
							progress: 'bar'
						}}
					/>
					: <Alert severity="error">Ошибка загрузки аудио!</Alert>)
			}
			{/*{src && !error ?*/}
			{/*	<AAudioPlayer*/}
			{/*		playList={playList}*/}
			{/*		activeUI={{*/}
			{/*			playButton: true,*/}
			{/*			trackTime: true,*/}
			{/*			progress: 'bar'*/}
			{/*		}}*/}
			{/*	/>*/}
			{/*	: <Alert severity="error">Ошибка загрузки аудио!</Alert>*/}
			{/*}*/}
		</div>
	)
}