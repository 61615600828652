import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import configureStore from './reduxm/store'
import dayjs from 'dayjs'

const { store } = configureStore()

dayjs.locale('ru')

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
   <Provider store={store}>
    <App />
   </Provider>
  </>
)
