/**
 * Redux query builder
 */
export default class ReduxQuery {
	constructor() {
		this.params = {
			// Keep result in store after repeat request
			keepPrevResult:           true,
			// Keep result in store after repeat request and error
			keepPrevResultError:      true,
			// Enable pagination
			enablePagination:         false,
			// Save prev result key (change only request)
			savePrevResult:           false,
			// Save prev result key (change only request) for HEAD requests
			savePrevResultHead:       true,
			// Don't show error message (disable flash message)
			disableErrorFlashMessage: false,
		}

		this.cloneInstance = () => {
			const instance = new ReduxQuery()
			instance.params = this.params
			return instance
		}

		this.toggleKeepPrevResult = (val = false, errorVal = false) => {
			this.params.keepPrevResult = val
			this.params.keepPrevResultError = errorVal
			return this
		}

		this.enablePagination = (val = true) => {
			this.params.enablePagination = val
			return this
		}

		this.savePrevResult = (val = true, head = true) => {
			this.params.savePrevResult = val
			this.params.savePrevResultHead = head
			return this
		}

		this.toggleErrorMessage = hide => {
			this.params.disableErrorFlashMessage = hide
			return this
		}

		this.getParams = () => this.params
	}
}
