import { Alert, Box, Button, LinearProgress, Snackbar, Typography } from '@mui/material'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder'
import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useTelegram } from '../../hooks/useTelegram'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import styled from '@emotion/styled'
import axios from 'axios'
import { WHISPER_API_URL } from '../../config'


const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
})

const LinearProgressWithLabel = (props) => {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">{`${Math.round(
					props.value,
				)}%`}</Typography>
			</Box>
		</Box>
	)
}

const ARecord = ({convertBlob}) => {
	const recorderControls = useAudioRecorder()

	return(
		<AudioRecorder
			onRecordingComplete={convertBlob}
			recorderControls={recorderControls}
			showVisualizer={true}
		/>
	)
}


export const AudioRecord = ({ uploadState, getStatus }) => {
	const [audioBlob, setAudioBlob] = useState(null)
	const [alert, setAlert] = useState({})
	const [isFetch, setIsFetch] = useState(false)
	const [prog, setProg] = useState(0)

	const { user } = useTelegram()

	const convertBlob = useCallback((blob) => {
		const type = blob.type.split(";")[0] ?? "audio/webm"
		const extension = type.split("/")[1] ?? 'ogg'
		const fileOfBlob = new File([blob], `record.${extension}`)
		setAudioBlob(fileOfBlob)
	}, [])

	const fetchUpload = useCallback( async () => {
		setIsFetch(true)
		setProg(0)

		const formData = new FormData()
		formData.append('userId', /*445068709*/user?.id)
		formData.append('file', audioBlob)

		let response = {}

		try{
			response = await axios.post(WHISPER_API_URL, formData, {
				onUploadProgress: (progressEvent) => {
					const progress = (progressEvent.loaded / progressEvent.total) * 100
					setProg(progress)
				}
			})
		}
		catch ( e ) {
			setAlert({severity: 'error', children: `Error ${JSON.stringify(e)}`, open: true})
		}
		if(response.status === 200){
			setAudioBlob(null)
			getStatus()
			setAlert({severity: 'success', children: 'success', open: true})
		}
		setIsFetch(false)
	}, [audioBlob])

	const handleClose = useCallback( () => {
		setAlert(prevState => ({...prevState, open: false}))
	}, [])

	const onChangeFile = useCallback((event) => {
			setAudioBlob(event.target.files[0])
		},[])

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px 0'}}>
			<Snackbar
				open={alert.open}
				autoHideDuration={5000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					severity={alert.severity}
					variant="filled"
					sx={{ width: '100%' }}
				>
					{alert.children}
				</Alert>
			</Snackbar>
			<Box sx={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 0 }}>
				{!isFetch &&
					<>
						<ARecord convertBlob={convertBlob} />
						<Button
							component="label"
							role={undefined}
							variant="contained"
							tabIndex={-1}
							startIcon={<CloudUploadIcon />}
							sx={{marginTop: '12px'}}
						>
							Select file
							<VisuallyHiddenInput
								type="file"
								onChange={onChangeFile}
								multiple={false}
							/>
						</Button>
					</>
				}
				{
				isFetch && <LinearProgressWithLabel value={prog} />
				}
			</Box>
			{
				audioBlob && !isFetch &&
					<Button
						variant="contained"
						sx={{marginTop: '12px'}}
						onClick={() => fetchUpload()}
						disabled={uploadState.fetching}
					>
						Upload
					</Button>
			}
		</div>
	)
}

AudioRecord.propTypes = {
	uploadState: PropTypes.object,
	upload: PropTypes.func,
	getStatus: PropTypes.func,
}

AudioRecord.defaultProps = {
	uploadState: {},
	upload: () => null,
	getStatus: () => null,
}
