import SagasHelper from '../../../helpers/SagasHelper'
import { takeLatest } from 'redux-saga/effects'
import { FORECAST_ACTIONS } from './actionTypes'
import { ForecastApi } from './index'

/**
 * Upload audio file to whisper recognize
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* getDay(action) {
	yield SagasHelper.defaultAction(action, ForecastApi.getDay, null, null)
}

function* update(action) {
	yield SagasHelper.defaultAction(action, ForecastApi.update, null, null)
}

export default [
	takeLatest(FORECAST_ACTIONS.GETDAY, getDay),
	takeLatest(FORECAST_ACTIONS.UPDATE, update),
]
