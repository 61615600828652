import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar, LocalizationProvider, PickersDay } from '@mui/x-date-pickers'
import ru from 'dayjs/locale/ru'
import { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Button } from '@mui/material'

const ServerDay = (props) => {
	const { powerDays = [], day, days, outsideCurrentMonth, selected, ...other } = props;

	const isPower = !outsideCurrentMonth && powerDays.findIndex(item => dayjs(item.startAt).date() === day.date()) >= 0
	const isEnable = !outsideCurrentMonth && days.indexOf(day.date()) >= 0

	const color = isPower ? (selected ? '#fff' : '#0099FF') : (selected ? '#fff' : '#18e9d9')
	const backgroundColor = isPower ? (selected ? '#0099FF' : '#000') : (selected ? '#18e9d9' : '#000')

	return (
		<PickersDay
			{...other}
			// style={{color: isPower ? 'orange' : selected ? '#fff' : '#18e9d9'}}
			style={{color, backgroundColor}}
			outsideCurrentMonth={outsideCurrentMonth}
			day={day}
			selected={selected}
		/>
	)
}

ServerDay.propTypes = {
	powerDays: PropTypes.array,
}

ServerDay.defaultProps = {
	powerDays: [],
}


export const Calendar = ({ powerDays, ...props }) => {
	const [days, setDays] = useState([17, 18, 19])

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ru}>
			<DateCalendar
				{ ...props }
				slots={{
					day: ServerDay,
				}}
				slotProps={{
					day: {
						powerDays,
						days,
					},
				}}
				views={['year','month', 'day']}
			/>
		</LocalizationProvider>
	)
}