

export const normalizeWeekDate = (date) => {
	const startOf = date.startOf('week')
	const endOf = date.endOf('week')
	const startAt = (startOf.month() < date.month() ? date.startOf('month') : startOf).format('MM/DD/YYYY')
	const endAt = (endOf.month() > date.month() ? date.endOf('month') : endOf).format('MM/DD/YYYY')

	return { startAt, endAt }
}
