import { DispatchSelector, StateSelector} from 'reduxm/selectors'
import { connect } from 'react-redux'
import { UserPage } from './index'

const mapStateToProps = state => ({
	getDayState: StateSelector.whisper.forecast.getDay(state),
	uploadStatus: StateSelector.whisper.recognize.status(state)
})

const mapDispatchToProps = {
	getDay: DispatchSelector.whisper.forecast.getDay,
	getStatus:  DispatchSelector.whisper.recognize.status,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPage)
