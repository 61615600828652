import { Calendar } from '../../Calendar'
import DataProvider from '../../reduxm/helpers/DataProvider'
import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import EventList from '../../Components/EventList/index.store'
import { normalizeWeekDate } from 'helpers/normalizeWeekDate'
import AudioRecord from '../../Components/AudioRecord/index.store'
import { Box, Divider, Link } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorIcon from '@mui/icons-material/Error'
import ScheduleIcon from '@mui/icons-material/Schedule'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DownloadIcon from '@mui/icons-material/Download'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { red, green } from '@mui/material/colors'
import { API_SIMPLE_STORAGE } from '../../config'
import { useTelegram } from '../../hooks/useTelegram'

const Icons = [
	<CloudUploadIcon sx={{color: '#0099FF'}} />,
	<ScheduleIcon sx={{color: '#0099FF'}} />,
	<CheckCircleOutlineIcon sx={{color: 'rgb(24, 233, 217)'}} />,
	<ErrorIcon sx={{ color: red[500]}} />
]

export const UserPage = ({ getDay, getDayState, getStatus, uploadStatus }) => {
	const [date, setDate] = useState(dayjs())

	const { isAdmin } = useTelegram()
	// const isAdmin = true

	useEffect(() => {
		getDay(DataProvider.buildQuery().addBody({ day: date.format('MM/DD/YYYY'), week: normalizeWeekDate(date)}))
		getStatus()
	}, [])

	const uploadedList = useMemo(() => {
		return uploadStatus.result?.map?.((el, index, arr) => {

			const textBlob = new Blob([el.text], {type: "text/plain;charset=UTF-8"})
			const url = window.URL.createObjectURL(textBlob)

			return (
				<div key={el.id}>
					<ListItem>
						<ListItemText
							sx={{justifyContent: 'space-around', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center'}}
							secondary={dayjs(el.createdAt).format('DD/MM/YYYY - HH:mm:ss')}
						>
							<Box sx={{display: 'flex', justifyContent: 'center'}}>{Icons[el.status]}</Box>
						</ListItemText>
						<Link
							href={`${API_SIMPLE_STORAGE}${el.url}`}
							target="_blank"
						>
							<DownloadIcon sx={{ color: green[500]}} />
						</Link>
						<Link href={url} target="_blank" download="text.txt"><TextSnippetIcon sx={{ color: green[500]}}/></Link>
					</ListItem>
					{index < arr.length - 1 && <Divider sx={{borderColor: 'rgb(24, 233, 217)'}} />}
				</div>
			)
		})
	}, [uploadStatus])

	const onChangeDate = (newDate) => {
		setDate(newDate)
		getDay(DataProvider.buildQuery().addBody({ day: newDate.format('MM/DD/YYYY'), week: normalizeWeekDate(newDate)}))
	}

	// const onLoadFile = ({ target }) => {
	// 	console.log('onLoadFile', target.files[0])
	// 	const reader = new FileReader()
	// 	reader.readAsDataURL(target.files[0])
	// 	reader.onload = () => {
	// 		console.log(reader.result)
	//
	// 		const query = DataProvider.buildQuery()
	// 		.addBody({blob: { src: reader.result }})
	// 		.setSuccessCallback(() => {
	// 			// setAudioBlob(null)
	// 			alert('seccess')
	// 			})
	// 		.setErrorCallback(e => {
	// 			alert('error')
	// 		})
	// 		upload(query)
	//
	// 	}
	// }

	return (
		<div style={{backgroundColor: '#000'}}>
			<Calendar
				value={date}
				onChange={onChangeDate}
				onMonthChange={onChangeDate}
				powerDays={getDayState?.result?.powerDays}
			/>
			<EventList date={date} />
			{isAdmin &&
			<>
				<AudioRecord />
				<List dense={true}>
					{uploadedList}
				</List>
			</>}
			{/*<Button*/}
			{/*	onClick={(d) => console.log('cliked',d)}*/}
			{/*	variant="contained"*/}
			{/*	component="label"*/}
			{/*>*/}
			{/*	Upload File*/}
			{/*	<input*/}
			{/*		onChange={onLoadFile}*/}
			{/*		type="file"*/}
			{/*		hidden*/}
			{/*	/>*/}
			{/*</Button>*/}
		</div>
	)
}

UserPage.propTypes = {
	getDayState: PropTypes.object,
	getDay:  PropTypes.func,
	getStatus:  PropTypes.func,
	uploadStatus: PropTypes.object,
}

UserPage.defaultProps = {
	getDayState: null,
	uploadStatus: {},
	getDay: () => null,
	getStatus: () => null,
}
