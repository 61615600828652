import { callApi } from './index'
/**
 * Normalize api endpoint.
 * E.g.: {list: 'endpoint'} => {list: sQ => callApi('endpoint', sQ)}
 *
 * @param {Object} apiObj
 *
 * @return {Object}
 * @constructor
 */

export const NormalizeApi = apiObj => Object.entries(apiObj).reduce((result, [key, url]) => {
	let callback = searchQuery => callApi(url, searchQuery)

	if (typeof url === 'function') {
		callback = searchQuery => url(searchQuery, u => callApi(u, searchQuery))
	}

	// eslint-disable-next-line no-param-reassign
	result[key] = callback
	return result
}, {})
