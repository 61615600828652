import './App.css'
import { useTelegram } from './hooks/useTelegram'
import { useEffect, useMemo } from 'react'
import { Container, createTheme, ThemeProvider } from '@mui/material'
import { themeOptions } from './Theme'
import { DispatchSelector, StateSelector } from './reduxm/selectors'
import { connect } from 'react-redux'
import UserPage from './Containers/UserPage/index.store'
import useNoSleep from './hooks/useNoSleep'

const App = () => {
 const {tg, isAdmin} = useTelegram()
 const theme = useMemo(() => createTheme(themeOptions()), [])

 useNoSleep(true)

 useEffect(() => {
  tg?.ready?.()
  tg?.expand?.()
 }, [])

 return (
   <ThemeProvider theme={theme}>
    <Container maxWidth="xs">
     <UserPage isAdmin={isAdmin} />
    </Container>
   </ThemeProvider>
  )
}

const mapStateToProps = state => ({
 uploadState: StateSelector.whisper.recognize.upload(state),
 getDayState: StateSelector.whisper.forecast.getDay(state),
})

const mapDispatchToProps = {
 upload: DispatchSelector.whisper.recognize.upload,
 getDay: DispatchSelector.whisper.forecast.getDay,
}



export default connect(mapStateToProps, mapDispatchToProps)(App);
