import { RecognizeReducer } from './modules/Whisper/recognite'
import { ForecastReducer } from './modules/Whisper/forecast'

import { combineReducers } from 'redux'

export const allReducers = {
	whisper: combineReducers({
		recognize: RecognizeReducer,
		forecast: ForecastReducer,
	})
}
