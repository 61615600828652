
/* light
{
  "bg_color": "#ffffff",                  //"#000000"
  "destructive_text_color": "#ff3b30",    //"#eb5545"
  "section_header_text_color": "#6d6d72", //"#8d8e93"
  "secondary_bg_color": "#efeff4",        //"#1c1c1d"
  "header_bg_color": "#f8f8f8",           //"#1a1a1a"
  "subtitle_text_color": "#8e8e93",       //"#98989e"
  "hint_color": "#8e8e93",                //"#98989e"
  "section_bg_color": "#ffffff",          //"#2c2c2e"
  "section_separator_color": "#c8c7cc",   //"#545458"
  "button_text_color": "#ffffff",         //"#ffffff"
  "text_color": "#000000",                //"#ffffff"
  "button_color": "#5679e3",              //"#6169ee"
  "link_color": "#5679e3",                //"#6169ee"
  "accent_text_color": "#5679e3"          //"#6169ee"
}
 */


export const themeOptions = () => {
	const theme = {
		"section_header_text_color": "#6d6d72",
			"text_color": "#000000",
			"bg_color": "#ffffff",
			"subtitle_text_color": "#8e8e93",
			"destructive_text_color": "#ff3b30",
			"hint_color": "#8e8e93",
			"button_color": "#18e9d9",
			"button_text_color": "#ffffff",
			"link_color": "#5679e3",
			"secondary_bg_color": "#efeff4",
			"section_separator_color": "#c8c7cc",
			"header_bg_color": "#f8f8f8",
			"section_bg_color": "#ffffff",
			"accent_text_color": "#5679e3"
	}

	return {
		palette: {
			primary: {
				main: theme.button_color,
				contrastText: theme.button_text_color,
			},
			background: {
				default: '#000000', //theme.bg_color ?? '#ffffff',
				paper: '#000000', //theme.section_separator_color ?? '#ffffff',
			},
			text: {
				primary: '#18e9d9',//theme.text_color ?? '#000000',
				secondary: '#18e9d9',//theme.text_color ?? '#000000',
			}
		},
		components: {
			MuiPickersCalendarHeader: {
					styleOverrides: {
						label: {
							color: '#0099FF',//theme.button_color ?? '#000000'
						},
						switchViewButton: {
							color: '#0099FF',//theme.button_color ?? '#000000'
						}
					}
				},
			MuiPickersArrowSwitcher: {
				styleOverrides: {
					button: {
						color: '#0099FF',//theme.button_color ?? '#000000'
					}
				}
			},
			MuiDrawer: {
				styleOverrides: {
					paper: {
						background: '#000000',//theme.bg_color ?? '#ffffff'
					}
				}
			},
			MuiPickersYear: {
				styleOverrides: {
					root: {
						color: '#0099FF',//theme.button_color ?? '#000000'
					}
				}
			},
			MuiPickersMonth: {
				styleOverrides: {
					root: {
						color: '#0099FF',//theme.button_color ?? '#000000'
					}
				}
			},
			MuiPickersDay: {
				styleOverrides: {
					root: {
						color: 'red',//theme.button_color ?? '#000000'
					}
				}
			},
			MuiDayCalendar: {
				styleOverrides: {
					weekDayLabel: {
						color: '#18e9d9',//theme.button_color ?? '#000000'
					}
				}
			},
			MuiButton: {
				styleOverrides: {
					root: {
						"&.Mui-disabled": {
							color: 'rgba(24,233,217,0.25)',
							border: '1px solid rgba(24,233,217,0.25)'
						}
					}
				}
			}
		},
	}
}
