import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistReducer } from 'redux-persist'
import { all } from 'redux-saga/effects'
import modulesSagas from './sagas'
import { allReducers } from './reducers'
import storage from 'redux-persist/lib/storage'

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
	key: 'root',
	storage,
}

const wind          = typeof (window) !== 'undefined' ? window : {}
const reduxDevTools =
	(wind.__REDUX_DEVTOOLS_EXTENSION__ &&
		wind.__REDUX_DEVTOOLS_EXTENSION__()) ||
	(a => a)

function* sagas() {
	yield all([
		...modulesSagas,
	])
}

const middleware = [
	sagaMiddleware,
]

const persistedReducer = persistReducer(persistConfig, combineReducers(allReducers))

const store     = createStore(
	persistedReducer,
	compose(applyMiddleware(...middleware), reduxDevTools),
)

sagaMiddleware.run(sagas)

export default () => ({
	store
})
