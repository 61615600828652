import { StateSelector, DispatchSelector} from 'reduxm/selectors'
import { connect } from 'react-redux'
import { EventList } from './index'

const mapStateToProps = state => ({
	getDayState: StateSelector.whisper.forecast.getDay(state),
	updateState: StateSelector.whisper.forecast.update(state),
})

const mapDispatchToProps = {
	update: DispatchSelector.whisper.forecast.update,
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList)
