import dayjs from 'dayjs'

export const forecastButtons = (date, response) => {
	const { result } = response ?? null

	const array = []

	if(result === null) return []

	if(result?.powerDays !== null && result?.powerDays.length > 0){
		const powerDayIndex = result?.powerDays.findIndex(item => dayjs(item?.startAt).date() === date.date())
		if(powerDayIndex >= 0){
			array.push({
				id: result?.powerDays[powerDayIndex].id,
				title: "День силы",
				date: dayjs(result?.powerDays[powerDayIndex]?.startAt).format('DD.MM.YYYY'),
				audio: result?.powerDays[powerDayIndex].audioSrc,
				text: result?.powerDays[powerDayIndex].text,
			})
		}
	}

	if(result?.day !== null){
		array.push({
			id: result?.day.id,
			title: "Прогноз на день",
			date: dayjs(result?.day?.startAt).format('DD.MM.YYYY'),
			audio: result?.day?.audioSrc,
			text: result?.day?.text,
		})
	}

	if(result?.week !== null){
		const respDate = dayjs(result?.week?.startAt)
		const startOf = respDate.startOf('week')
		const endOf = respDate.endOf('week')
		const startWeek = startOf.month() < respDate.month() ? respDate.startOf('month') : startOf
		const endWeek = endOf.month() > respDate.month() ? respDate.endOf('month') : endOf
		const weak = `${startWeek.format('DD.MM')} - ${endWeek.format('DD.MM')}`

		array.push({
			id: result?.week.id,
			title: "Прогноз на неделю",
			date: weak,
			audio: result?.week?.audioSrc,
			text: result?.week?.text,
		})
	}

	if(result?.month !== null){
		array.push({
			id: result?.month.id,
			title: "Прогноз на",
			date: dayjs(result?.month?.startAt).format('MMMM YYYY'),
			audio: result?.month?.audioSrc,
			text: result?.month?.text,
		})
	}

	if(result?.year !== null){
		array.push({
			id: result?.year.id,
			title: "Прогноз на год",
			date: dayjs(result?.year?.startAt).format('YYYY'),
			audio: result?.year?.audioSrc,
			text: result?.year?.text,
		})
	}

	return array
}
