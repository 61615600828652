import SagasHelper from '../../../helpers/SagasHelper'
import { takeLatest } from 'redux-saga/effects'
import { RECOGNIZE_ACTIONS } from './actionTypes'
import { RecognizeApi } from './index'

/**
 * Upload audio file to whisper recognize
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* upload(action) {
	yield SagasHelper.defaultAction(action, RecognizeApi.upload, null, null)
}

function* status(action) {
	yield SagasHelper.defaultAction(action, RecognizeApi.status, null, null)
}

export default [
	takeLatest(RECOGNIZE_ACTIONS.UPLOAD, upload),
	takeLatest(RECOGNIZE_ACTIONS.STATUS, status),
]
