import { useCallback, useMemo, useState } from 'react'
import {
	Alert,
	Button,
	CircularProgress,
	IconButton,
	Snackbar,
	SwipeableDrawer,
	TextField,
} from '@mui/material'
import { AudioPlayer } from '../AudioPlayer'
import PropTypes from 'prop-types'
import { forecastButtons } from './forecastButtons'
import { useTelegram } from '../../hooks/useTelegram'
import DataProvider from '../../reduxm/helpers/DataProvider'
import CloseIcon from '@mui/icons-material/Close'
import styled from '@emotion/styled'
import useDetectKeyboardOpen from "use-detect-keyboard-open"

const PageData = ({ data, update }) => {
	const [changed, setChanged] = useState(false)
	const [alert, setAlert] = useState({})
	const { isAdmin, user } = useTelegram()

	const isKeyboardOpen = useDetectKeyboardOpen()

	const onSubmit = useCallback(event => {
		event.preventDefault()
		const { target: { text: { value } } } = event

		const query = DataProvider.buildQuery().addBody( {forecast: {id: data.id, text: value}, userId: user?.id})
		.setSuccessCallback(() => {
			setAlert({severity: 'success', children: 'success update', open: true})
			setChanged(false)
		})
		.setErrorCallback(e => {
			setAlert({severity: 'error', children: `Error ${JSON.stringify(e)}`, open: true})
		})
		update(query)

	},[data, update, user])

	const handleClose = useCallback(() => {
		setAlert(prevState => ({...prevState, open: false}))
	}, [])

	return (
		<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '0 40px'}}>
			<Snackbar
				open={alert.open}
				autoHideDuration={5000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					severity={alert.severity}
					variant="filled"
					sx={{ width: '100%' }}
				>
					{alert.children}
				</Alert>
			</Snackbar>
			<h4 style={{ color: '#0099FF'}}>{ data.title + ' ' + data.date }</h4>
			<AudioPlayer audio={data.audio}/>
			{isAdmin ?
				<form
					onSubmit={onSubmit}
					onReset={() => setChanged(false)}
					style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: (isKeyboardOpen ? '300px' : 0)}}
				>
					<TextField
						id="text"
						multiline
						defaultValue={data.text}
						sx={{width: '100%'}}
						onChange={() => setChanged(true)}
					/>
					<div style={{padding: '20px 0', display: 'flex'}}>
						<Button disabled={!changed} variant="outlined" type="reset" sx={{marginRight: '10px'}}>Cancel</Button>
						<Button disabled={!changed} variant="contained" type={"submit"} sx={{marginLeft: '10px'}}>Update</Button>
					</div>
			</form>
				: <div style={{ paddingBottom: '40px'}}>{ data.text }</div>}

		</div>
	)
}

const GradientButton = styled(Button)`
  background: linear-gradient(90deg, #18E9D9 40%, #0000FF 100%);
  border-radius: 4px;
  border: 0;
  color: white;
  width: 100%;
`;


export const EventList = ({ date, getDayState, update }) => {
	const [state, setState] = useState({isOpen: false, audio: "", text: "", title: "", date: ""})

	const drawForecastButtons = useMemo(() => {
		if (getDayState.fetching) return (
			<div style={{display: 'flex', justifyContent: 'center'}}>
				<CircularProgress />
			</div>
		)

		const forecasts = forecastButtons(date, getDayState).map(item => (
			<div key={item.id} style={{padding: '4px 0'}}>
				<GradientButton variant="contained" sx={{ width: '100%' }} onClick={() => setState({isOpen: true, ...item})}>
					{item.title + ' ' + item.date}
				</GradientButton>
			</div>
		))

		if(forecasts.length > 0) return forecasts

		return (
			<div style={{padding: '4px 0', color: '#18E9D9', textAlign: 'center'}}>
				Прогнозы не найдены
			</div>
		)
		}, [date, getDayState])


	return (
		<div>
			{drawForecastButtons}
			<SwipeableDrawer
				anchor={'bottom'}
				open={state.isOpen}
				onClose={() => setState(prevState => ({...prevState, isOpen: false}))}
				onOpen={() => null}
			>
				<IconButton
					color='primary'
					sx={{ position: 'absolute', top: '10px', right: '10px' }}
					onClick={() => setState(prevState => ({...prevState, isOpen: false}))}
				>
					<CloseIcon sx={{color: '#0099FF'}} />
				</IconButton>
				<div style={{ minHeight: '45vh', borderTop: '#0099FF 1px solid', borderRadius: '50px'}}> {/*style={{ height: '95vh'}}*/}
					<PageData data={state} update={update} />
				</div>
			</SwipeableDrawer>
		</div>
	)
}

EventList.propTypes = {
	date: PropTypes.object,
	getDayState: PropTypes.object,
	updateState: PropTypes.object,
	update: PropTypes.func,
}

EventList.defaultProps = {
	date: {},
	getDayState: {},
	updateState: {},
	update: () => null,
}

